import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueCryptojs from "vue-cryptojs";
import VueSession from 'vue-session'
import vuetify from './plugins/vuetify'
import VueGlobalVariable from "vue-global-var";

Vue.use(VueCryptojs)
Vue.use(VueSession)
Vue.use(VueAxios, axios);
Vue.use(VueGlobalVariable, {
  globals: {
    version: "1.0",
    env_url: 'https://diagnosiexport.cambralleida.org/api/',
  }
})
Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
