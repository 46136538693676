<template>
  <v-container>
    <v-row justify="space-around" align="center" class="align-content-center">
      <v-col cols="12">
        <h5>Progrés de l'autoavaluació</h5>
        <v-progress-linear
            :disabled="true"
            :value="progress"
            color="#ba0c2f"
            height="25">
          <strong>{{ parseFloat(progress).toFixed(2) }}%</strong>
        </v-progress-linear>
      </v-col>
      <v-col cols="12" class="align-center align-content-center">
        <v-stepper v-model="e1" color="#ba0c2f">
          <v-stepper-header>
            <v-stepper-step
                color="#ba0c2f"
                :complete="e1 > 1"
                step="1">
              Dades de l'empresa
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step
                color="#ba0c2f"
                :complete="e1 > 2"
                step="2">
              Anàlisi del punt de partida
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step color="#ba0c2f" :complete="e1 > 3"
                            step="3">
              Nivell de planificació
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step color="#ba0c2f" :complete="e1 > 4"
                            step="4">
              Característiques del producte/servei
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step color="#ba0c2f" :complete="e1 > 5"
                            step="5">
              Accés a nous clients/mercats
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step color="#ba0c2f" :complete="e1 > 6"
                            step="6">
              Dades d'identificació
            </v-stepper-step>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card class="mx-auto pl-10 pr-10">
                <v-card-title>
                  <h3 class="titleCard">Dades de l'empresa</h3>
                </v-card-title>
                <v-col cols="2" class="mt-n4 ml-n2 mb-5">
                  <v-divider></v-divider>
                </v-col>
                <v-row>
                  <v-col cols="6" v-for="question in dadesq" v-bind:key="question.id">
                    <transition name="fade">
                      <question @selected="setSector" @subselected="setSubSector" :selected-sector="sector"  :question="question.question" :type="question.type" v-if="question.id <= answers"
                                :responses="question.responses" :id="question.id" :part="0" :update-score="updateScore"/>

                    </transition>
                  </v-col>
                </v-row>
              </v-card>
            </v-stepper-content>
            <v-stepper-content step="2">
              <v-card class="mx-auto pl-10 pr-10">
                <v-card-title>
                  <h3 class="titleCard">ANÀLISI DEL PUNT DE PARTIDA</h3>
                </v-card-title>
                <v-col cols="6" class="mt-n4 ml-n2 mb-5">
                  <v-divider></v-divider>
                </v-col>
                <v-row>
                  <v-col cols="6" v-for="question in analisisq" v-bind:key="question.id">
                    <question :question="question.question" :type="question.type" v-if="question.id <= answers"
                              :responses="question.responses" :id="question.id" :part="1" :update-score="updateScore"/>
                  </v-col>
                </v-row>
              </v-card>
            </v-stepper-content>
            <v-stepper-content step="3">
              <v-card class="mx-auto pl-10 pr-10">
                <v-card-title>
                  <h3 class="titleCard">NIVELL DE PLANIFICACIÓ</h3>
                </v-card-title>
                <v-col cols="5" class="mt-n4 ml-n2 mb-5">
                  <v-divider></v-divider>
                </v-col>
                <v-row>
                  <v-col cols="6" v-for="question in planificacioq" v-bind:key="question.id">
                    <question :question="question.question" :type="question.type" v-if="question.id <= answers"
                              :responses="question.responses" :id="question.id" :part="2" :update-score="updateScore"/>
                  </v-col>
                </v-row>
              </v-card>
            </v-stepper-content>
            <v-stepper-content step="4">
              <v-card class="mx-auto pl-10 pr-10">
                <v-card-title>
                  <h3 class="titleCard">CARACTERÍSTIQUES DEL PRODUCTE / SERVEI</h3>
                </v-card-title>
                <v-col cols="5" class="mt-n4 ml-n2 mb-5">
                  <v-divider></v-divider>
                </v-col>
                <v-row>
                  <v-col cols="6" v-for="question in caracteristicasq" v-bind:key="question.id">
                    <question :question="question.question" :type="question.type" v-if="question.id <= answers"
                              :responses="question.responses" :id="question.id" :part="3" :update-score="updateScore"/>
                  </v-col>
                </v-row>
              </v-card>
            </v-stepper-content>
            <v-stepper-content step="5">
              <v-card class="mx-auto pl-10 pr-10">
                <v-card-title>
                  <h3 class="titleCard">ACCÉS A NOUS CLIENTS / MERCATS</h3>
                </v-card-title>
                <v-col cols="5" class="mt-n4 ml-n2 mb-5">
                  <v-divider></v-divider>
                </v-col>
                <v-row>
                  <v-col cols="6" v-for="question in nousclientsq" v-bind:key="question.id">
                    <question :question="question.question" :type="question.type" v-if="question.id <= answers"
                              :responses="question.responses" :id="question.id" :part="4" :update-score="updateScore"/>
                  </v-col>
                </v-row>
              </v-card>
            </v-stepper-content>
            <v-stepper-content step="6">
              <v-card class="mx-auto pl-10 pr-10">
                <v-card-title>
                  <h3 class="titleCard">Dades d'Identificació</h3>
                </v-card-title>

                <v-col cols="6" class="mt-n4 ml-n2 mb-5">
                  <v-divider></v-divider>
                </v-col>
                <v-row>
                  <v-col cols="6">
                    <h6>Seleccioneu tipus d'organització</h6>
                    <v-radio-group v-model="radioGroup" row>
                      <v-radio color="#ba0c2f" label="Persona física" value="fisica"/>
                      <v-radio color="#ba0c2f" label="Persona jurídica" value="juridica"/>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field color="#ba0c2f" v-model="name"
                        label="Nom i cognoms / Raó social*"/>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field color="#ba0c2f" v-model="nif"
                        label="NIF"/>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field color="#ba0c2f" v-model="town"
                        label="Municipi*"/>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field color="#ba0c2f" v-model="year"
                        label="Any d'inici de l'activitat"/>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field color="#ba0c2f" v-model="mail"
                        label="Correu electrónic *"/>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field color="#ba0c2f" v-model="web"
                        label="Página web"/>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field color="#ba0c2f" v-model="phone"
                        label="Telèfon"/>
                  </v-col>
                  <v-col cols="6">
                    <v-textarea color="#ba0c2f" v-model="coments"
                        label="Observacions o comentaris"/>
                  </v-col>
                  <v-col cols="12">
                    <v-checkbox color="#ba0c2f"
                        v-model="checkbox"
                        label="D’acord amb l’article 5 de la Llei orgànica 15/1999, de 13 de desembre, de protecció de dades de caràcter personal, us informem que les vostres dades personals seran recollides, incorporades i tractades al fitxer automatizat anomenat “Comunicacions”,  regulat a l’Ordre EMO/284/2014, de 15 de setembre, per la qual es creen fitxers de dades de caràcter personal de la Cambra de Comerç de Lleida
                         "
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12">
                    <v-alert
                        v-if="emptyerr"
                        border="right"
                        colored-border
                        type="error"
                        elevation="2">
                      Si us plau, omple tots els camps obligatoris marcats amb *
                    </v-alert>
                  </v-col>
                  <v-col cols="12" style="text-align: center;align-self: center;">
                    <v-btn elevation="2" color="#ba0c2f" dark @click="setUserData" :disabled="checkbox == 0" > Obtenir resultats </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import Question from "../components/home/question";
import {dadesempresa} from "../components/questions/dadesempresa";
import {analisisquestions} from "../components/questions/analisispartida";
import {planificacioquerys} from "../components/questions/planificacio";
import {caracteristicasproducto} from "../components/questions/caracteristicasproducto";
import {nousclients} from "../components/questions/nousclients";

export default {
  name: 'Home',
  components: {Question},
  data() {
    return {
      score: 0,
      answers: 1,
      e1: 1,
      checkbox:false,
      radioGroup:null,
      progress: 0,
      partAnswers:[],
      partScore: [],
      analisisq: analisisquestions,
      dadesq: dadesempresa,
      planificacioq: planificacioquerys,
      caracteristicasq: caracteristicasproducto,
      nousclientsq: nousclients,
      name:null,
      nif:null,
      town:null,
      year:null,
      mail:null,
      phone:null,
      web:null,
      coments:null,
      token:null,
      sector:null,
      subsector:null,
      emptyerr:false
    }
  },
  methods: {
    setSector(selsect){
      this.sector = selsect;
    },
    setSubSector(selsect){
      this.subsector = selsect;
    },
    updateScore(part, points,answerID, index) {
      let formData = new FormData();
      formData.set('session', this.$session.id())
      formData.set('qid',answerID);
      formData.set('answer',index);
      formData.set('points',points);
      let url = this.env_url + 'setresponse.php'
      this.axios.post(url, formData).then();
      let found = false;
      this.partAnswers.map((value)=>{
        if(value.part === part && value.id === answerID)
          found = true
      })
      if(!found){
        this.partAnswers.push({part:part, id:answerID})
        this.progress = this.progress + 3.03
        this.score = this.score + points;
        this.answers = this.answers + 1;
        if (this.partScore[part] !== undefined){
          this.partScore[part].points = this.partScore[part].points + points;
        } else{
          this.partScore.push({points:points})
        }
        if (this.progress === 3.03 * 5)
          this.e1 = 2
        if (this.progress >= 3.03 * 12)
          this.e1 = 3
        if (this.progress >= 3.03 * 18)
          this.e1 = 4
        if (this.progress >= 3.03 * 28)
          this.e1 = 5
        if (this.progress >= 99.99){
          this.progress = 100;
          this.e1 = 6
        }
      }
    },
    setUserData(){
      if(this.name === null || this.mail === null || !this.mail.includes('@'))
        this.emptyerr = true;
      else {
        this.emptyerr = false;
        let formData = new FormData();
        const key = Date.now().toString();
        this.token = this.CryptoJS.AES.encrypt(this.$session.id(), key).toString().substr(33, 10);
        formData.set('session', this.$session.id())
        formData.set('type', this.radioGroup)
        formData.set('name', this.name);
        formData.set('nif', this.nif);
        formData.set('town', this.town);
        formData.set('year', this.year);
        formData.set('mail', this.mail);
        formData.set('web', this.web);
        formData.set('phone', this.phone);
        formData.set('coments', this.coments);
        formData.set('token', this.token);
        formData.set('sector', this.sector);
        formData.set('subsector', this.subsector)
        let url = this.env_url + 'setclientdata.php'
        this.axios.post(url, formData).then(() => {
          this.getResults()
        });
      }
    },
    getResults(){
      this.$session.set('partialScore', this.partScore)
      this.$session.set('score',this.score)
      this.$router.push('/resultats/'+this.token)
    }
  }


}
</script>

<style scoped>
.titleCard {
  color: #ba0c2f;
  margin-left: -15px;
}
</style>
