<template>
  <section>
    <h3 ref='focusMe'>{{ question }}</h3>
    <v-radio-group v-model="radioGroup" v-if="type==='check'">
      <v-radio color="#ba0c2f" v-for="(response,index) in responses" v-bind:key="response.title"
               :value="response.title" :label="response.title" @change="updateScore(part,response.points,id,index)"/>
    </v-radio-group>
    <v-select v-else-if="id === 1" color="#ba0c2f" v-model="selResp" :items="selectOptions"
              @change="updateScore(part,0,id,0)"/>
    <v-select v-else-if="id === 2" color="#ba0c2f" v-model="selResp2" :items="selectOptions"
              @change="updateScore(part,0,id,0)"/>
  </section>
</template>

<script>
export default {
  name: "question",
  props: ['question', 'responses', 'id', 'part', 'type', 'updateScore', 'selectedSector'],
  mounted() {
    if (this.type === 'select') {
      if (this.id === 1)
        this.responses.forEach(response => {
          this.selectOptions.push({value: response.title, text: response.title})
        })
      if (this.id === 2) {
        this.responses.forEach(response => {
          if(response.key === this.selectedSector){
            response.value.forEach(resps=>{
              this.selectOptions.push({value: resps.title, text: resps.title})
            })
          }
        })
      }
    }
    this.$refs.focusMe.focus()
  },
  data() {
    return {
      radioGroup: null,
      selectOptions: [],
      selResp: 0,
      selResp2:0
    }
  },
  methods:{
    setSubsector(newSelection){
      this.selectOptions = []
      this.responses.forEach(response => {
        if(response.key === newSelection){
          response.value.forEach(resps=>{
            this.selectOptions.push({value: resps.title, text: resps.title})
          })
        }
      })
    }
  },
  watch: {
    selResp: function (newSel) {
      this.$emit('selected', newSel)
    },
    selResp2: function (newSel){
      this.$emit('subselected', newSel)
    },
    selectedSector: function (newSelection){
      if(this.id === 2)
        this.setSubsector(newSelection)
    }
  }
}
</script>

<style scoped>

</style>