<template>
  <v-app>
    <v-app-bar
      app
      height="100"
      color="white" light class="noprint">
      <div class="d-flex align-center">
        <a href="https://cambralleida.org">
          <img src="https://www.cambralleida.org/wp-content/uploads/2019/02/Cambra-Lleida-logotip.png" width="250"/>

        </a>
        <img src="https://diagnosiexport.cambralleida.org/api/logopromeco.png" width="100"/>
        <img src="https://diagnosiexport.cambralleida.org/api/logopromeco.jpg" width="100"/>
        <h2>Test d'autodiagnosi per la internalització de l'empresa </h2>
      </div>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <div class="d-flex align-end">
        <div class="ml-10">
          <Translator :countries="countries"/>
        </div>

      </div>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import {Translator} from 'vue-google-translate'
export default {

  name: 'App',
  data(){
    return{
      countries: [
        {
          code: 'en|af',
          title: 'Afrikaans',
        },
        {
          code: 'en|sq',
          title: 'Albanian',
        },
        {
          code: 'en|ar',
          title: 'Arabic',
        },
        {
          code: 'en|hy',
          title: 'Armenian',
        },
        {
          code: 'en|az',
          title: 'Azerbaijani',
        },
        {
          code: 'en|eu',
          title: 'Basque',
        },
        {
          code: 'en|be',
          title: 'Belarusian',
        },
        {
          code: 'en|bg',
          title: 'Bulgarian',
        },
        {
          code: 'en|ca',
          title: 'Catalan',
        },
        {
          code: 'en|zh-CN',
          title: 'Chinese (Simplified)',
        },
        {
          code: 'en|zh-TW',
          title: 'Chinese (Traditional)',
        },
        {
          code: 'en|hr',
          title: 'Croatian',
        },
        {
          code: 'en|cs',
          title: 'Czech',
        },

        {
          code: 'en|da',
          title: 'Danish',
        },
        {
          code: 'en|nl',
          title: 'Dutch',
        },
        {
          code: 'en|en',
          title: 'English',
        },
        {
          code: 'en|et',
          title: 'Estonian',
        },
        {
          code: 'en|tl',
          title: 'Filipino',
        },
        {
          code: 'en|fi',
          title: 'Finnish',
        },
        {
          code: 'en|fr',
          title: 'French',
        },

        {
          code: 'en|de',
          title: 'German',
        },
        {
          code: 'en|el',
          title: 'Greek',
        },
        {
          code: 'en|hu',
          title: 'Hungarian',
        },
        {
          code: 'en|id',
          title: 'Indonesian',
        },
        {
          code: 'en|ga',
          title: 'Irish',
        },
        {
          code: 'en|it',
          title: 'Italian',
        },
        {
          code: 'en|ja',
          title: 'Japanese',
        },
        {
          code: 'en|ko',
          title: 'Korean',
        },
        {
          code: 'en|lt',
          title: 'Lithuanian',
        },
        {
          code: 'en|ms',
          title: 'Malay',
        },
        {
          code: 'en|no',
          title: 'Norwegian',
        },
        {
          code: 'en|pl',
          title: 'Polish',
        },
        {
          code: 'en|pt',
          title: 'Portuguese',
        },
        {
          code: 'en|ro',
          title: 'Romanian',
        },
        {
          code: 'en|ru',
          title: 'Russian',
        },
        {
          code: 'en|es',
          title: 'Spanish',
        },
        {
          code: 'en|sv',
          title: 'Swedish',
        },
        {
          code: 'en|th',
          title: 'Thai',
        },
        {
          code: 'en|tr',
          title: 'Turkish',
        },
        {
          code: 'en|uk',
          title: 'Ukrainian',
        },
      ]
    }
  },
  mounted() {
    if(!this.$session.exists())
      this.$session.start();
    this.setUser();
  },
  components:{
    Translator
  },
  methods:{
    setUser(){
      let formData = new FormData();
      formData.set('session', this.$session.id())
      let url = this.env_url + 'setuser.php'
      this.axios.post(url, formData).then();
    }
  }
};
</script>
<style>
@media print{
  .noprint{
    display: none;
  }
}
.langSelect {
  background: linen;
  appearance: auto;
  padding: 0.5em;
}
.skiptranslate.goog-te-gadget {
  display: none;
}
</style>
